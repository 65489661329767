<template>
  <div class="relative z-10 flex px-12 mt-6 desk:mt-12 desk:px-0">
    <div
      class="flex items-center"
    >
      <nuxt-link
        :href="$manualUrl(brandlistUrl)"
        class="hoverdistance btn--text mobOnly:text-12"
      >
        {{ globalContent.brandlistPage?.story?.name }}
      </nuxt-link>
    </div>
    <span class="px-8 text-12 desk:text-14 font-outfit">/</span>
    <div
      v-if="globalContent.getBrandLink(productItem.brand.code)"
      class="flex items-center"
    >
      <nuxt-link
        :href="globalContent.getBrandLink(productItem.brand.code)"
        class="hoverdistance btn--text mobOnly:text-12"
      >
        {{ productItem.brand.name }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductModel } from '~/models/product';
import { useGlobalContentStore } from '~/store/globalContent';

const globalContent = useGlobalContentStore();

defineProps<{
  productItem: ProductModel,
}>();

const brandlistUrl = globalContent.brandlistPage?.story?.full_slug?.replace(globalContent.getStoryblokBasePath + '/', '') || '/';

</script>

<style scoped lang="postcss">

</style>
