<template>
  <div class="ProductDefaultLayout -mt-[30px] desk:grid grid-cols-[80px_minmax(0,_1fr)_repeat(10,_minmax(0,_1fr))] gap-x-12 desk:mt-0 desk:mb-80 desk:pt-16">
    <!-- spalt 1 -->
    <div class="hidden desk:block col-span-1">
      <div id="ProductImageThumbnails" class=" relative h-full max-h-[468px]" />
    </div>

    <!-- spalt 2 -->
    <div class="mobOnly:w-full mobOnly:mb-24 desk:col-span-6 text-left">
      <ProductImage
        :product-item="productItem"
        :current-image-list="currentImageList"
        :active-variant="activeVariant"
      />

      <!-- * * * * * * desktop details -->
      <div class="mt-12 mobOnly:hidden">
        <ProductDetails
          :product-item="productItem"
          :active-variant="activeVariant"
        />

        <!-- * * * * desktop bundles -->
        <ProductBundles v-if="activeVariant.isIncludedInStructureArticle" :active-variant="activeVariant" />
      </div>
    </div>

    <!-- spalt 3 -->
    <div class="mobOnly:w-full desk:col-span-5 desk:pl-28 flex flex-col justify-start gap-16">
      <div class="mobOnly:px-12 flex flex-col justify-start gap-16">
        <!-- * * * * * * name & category -->
        <NameAndCategory
          :product-item="productItem"
          :active-variant="activeVariant"
        />
        <!-- * * * * * * structure article -->
        <div v-if="productItem.isStructureArticle && activeVariant.structureArticleProducts?.length" class="my-12">
          <p class="type-headline-sm">{{ $t('productPage.bundles.contains') }}</p>
          <component
            :is="activeVariant.structureArticleProducts.length > 6 ? ReadMore : 'div'"
            :max-height="300"
            :as-button="true"
            :closed-text="$t('productPage.bundles.showAll', {num: activeVariant.structureArticleProducts.length})"
            :open-text="$t('productPage.bundles.showLess')"
            :class="{
              'mb-24': activeVariant.structureArticleProducts.length > 6
            }"
          >
            <div class="flex flex-col">
              <component
                :is="product.url ? NuxtLink : 'div'"
                v-for="product in activeVariant.structureArticleProducts"
                :key="product.partNo"
                :to="product.url"
                class="py-6 flex items-center w-full"
              >
                <div class="w-32 h-40 desk:w-40 desk:h-48 mr-12 relative flex items-center justify-center">
                  <nuxt-img
                    provider="norce"
                    sizes="80px"
                    :src="product.image"
                    class="object-contain w-32 h-32 desk:w-40 desk:h-40"
                  />
                  <div class="imageOverlay absolute top-0 left-0 w-full h-full pointer-events-none" />
                </div>
                <div class="flex justify-between flex-1 type-xs desk:type-sm">
                  <div class="w-full">
                    <span>{{ product.quantity }} x {{ product.name }}</span>
                    <div class="text-dark">{{ product.partNo }}</div>
                  </div>
                </div>
              </component>
            </div>
          </component>
        </div>

        <!-- * * * * * * price -->
        <div class="block">
          <client-only>
            <ProductPrice
              :key="activeVariant.partNo"
              :product-item="productItem"
              :active-variant="activeVariant"
              design="standard"
            />
          </client-only>
        </div>
      </div>

      <!-- * * * * * * variant selector -->
      <VariantSelector
        :product-item="productItem"
        :active-variant="activeVariant"
        design="standard"
        @update-active-variant="(variant) => $emit('updateActiveVariant', variant)"
      />
      <div class="mobOnly:px-12">
        <!-- * * * * * * quantity and buy button -->
        <BuyBox
          v-if="activeVariant"
          :key="activeVariant.partNo"
          :active-variant="activeVariant"
          :product-item="productItem"
          design="standard"
        />
      </div>

      <div class="desk:hidden">
        <!-- * * * * * * mobile details -->
        <ProductDetails
          :product-item="productItem"
          :active-variant="activeVariant"
        />

        <!-- * * * * mobile bundles -->
        <ProductBundles v-if="activeVariant.isIncludedInStructureArticle" :active-variant="activeVariant" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VariantSelector from '~/components/product-page/VariantSelector.vue';
import BuyBox from '~/components/product-page/BuyBox.vue';
import ProductImage from '~/components/product-page/ProductImage.vue';
import NameAndCategory from '~/components/product-page/NameAndCategory.vue';
import ProductPrice from '~/components/product-page/ProductPrice.vue';
import ProductDetails from '~/components/product-page/ProductDetails.vue';
import ProductBundles from '~/components/product-page/ProductBundles.vue';
import { ProductModel } from '~/models/product';
import { ProductVariantModel } from '~/models/productVariant';
import { NorceMediaSetCodes } from '~/constants/norceCodes';

const NuxtLink = resolveComponent('NuxtLink');
const ReadMore = resolveComponent('ReadMore');

defineEmits<{
  (e: 'updateActiveVariant', obj: ProductVariantModel): void;
}>();

defineProps<{
  productItem: ProductModel,
  activeVariant: ProductVariantModel,
  currentImageList: NorceMediaSetCodes,
}>();

</script>
