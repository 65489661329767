<template>
  <div class="ProductImage relative">
    <transition name="fade">
      <ModalVideoPlayer
        v-if="showVideo !== ''"
        :video-file="showVideo"
        @close="closeVideo"
      />
    </transition>
    <div
      v-if="productItem.isNewProduct || activeVariant.isOnSaleForUser"
      class="
      absolute top-12 right-12 z-40
      flex items-center justify-center w-64 h-64 desk:w-96 desk:h-96
      type-xs-medium desk:type-base-medium text-lightest text-center leading-single
      rounded-full overflow-hidden pointer-events-none -rotate-12"
      :class="{
        'bg-darkest': productItem.isNewProduct,
        'bg-criticalDark': activeVariant.isOnSaleForUser,
      }"
    >
      <div v-if="productItem.isNewProduct">{{ $t('flag.new') }}</div>
      <div v-else>{{ $t('flag.sale') }}</div>
    </div>
    <div
      v-else-if="productItem.isPercentageCampaign || productItem.isStepCampaigns || activeVariant.hasBuyMoreCampaign"
      class="
      absolute top-12 right-12 z-40
      flex items-center justify-center w-64 h-64 desk:w-96 desk:h-96
      type-xxs-medium desk:type-sm-medium text-lightest text-center leading-single
      bg-criticalDark
      rounded-full overflow-hidden pointer-events-none -rotate-12"
    >
      <div class="p-4">{{ $t('buyMore.plp.flag') }}</div>
    </div>

    <FullScreenGallery
      v-if="galleryVisible"
      :images="images"
      :start-index="startGalleryIndex"
      :current-image-list="currentImageList"
      :product-item="productItem"
      @close="galleryVisible = false"
    />
    <div
      v-if="images.length > 0"
      class="relative"
    >
      <SimpleSwiper
        ref="mainSwiper"
        :images="images"
        :slide-margin="2"
        :slide-width="100"
        sizes="mob375:100vw mob390:100vw mob414:100vw desk:100vw"
        slide-classes="cursor-pointer"
        preset="pdpimage"
        :lazy-first-image="false"
        :product-item="productItem"
        @image-click="onImageClick"
        @set-slide="setSlide"
      />
      <div class="imageOverlay absolute top-0 left-0 w-full h-full pointer-events-none" />
    </div>

    <div v-else class="relative aspect-square">
      <img
        src="/icons/no-image.svg"
        alt="no image"
        class="w-full h-full object-cover"
      >
      <div class="imageOverlay absolute top-0 left-0 w-full h-full pointer-events-none" />
    </div>
    <client-only>
      <Teleport to="#ProductImageThumbnails">
        <div class="relative w-full h-full">
          <div
            v-if="images.length > 4"
            class="hidden desk:block absolute w-full h-full"
          >
            <div
              class="swiper-button-prev !top-8 !left-24 rotate-90"
            />
            <div
              class="swiper-button-next !-bottom-16 !top-auto !right-24 rotate-90"
            />
          </div>

          <Swiper
            direction="vertical"
            :space-between="12"
            :slides-per-view="5"
            :slides-offset-before="0"
            :slides-offset-after="0"
            :modules="[SwiperNavigation, SwiperMousewheel]"
            :mousewheel="true"
            :navigation="{
              nextEl: `.swiper-button-next`,
              prevEl: `.swiper-button-prev`,
            }"
            class="w-full h-full"
          >
            <SwiperSlide
              v-for="(image, index) in images"
              :key="image.file"
              class="w-80 h-80"
            >
              <div
                class="w-full h-full relative border border-lightest cursor-pointer transition-all"
                :class="{
                  '!border-darkest border-animation': index === thumbSelected
                }"
                @click.prevent="clickThumb(index)"
              >
                <div
                  v-if="image.linkedVideo"
                  class="absolute w-full h-full flex items-center justify-center aspect-square"
                >
                  <div class="w-32 h-32 bg-darker flex items-center justify-center">
                    <img
                      alt="play"
                      src="/icons/play-filled-inv.svg"
                      class="w-16 h-16"
                    >
                  </div>
                </div>
                <nuxt-img
                  preset="standard"
                  provider="norce"
                  :src="image.file"
                  sizes="80px"
                  class="w-full h-full object-contain"
                  :class="{
                    'object-cover': productItem.extendedLayout,
                  }"
                />
                <div class="imageOverlay absolute top-0 left-0 w-full h-full pointer-events-none" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </Teleport>
    </client-only>
  </div>
</template>

<script lang="ts" setup>

import { useUiStore } from '~/store/ui';
import { ProductModel } from '~/models/product';
import SimpleSwiper from '~/components/globals/SimpleSwiper.vue';
import FullScreenGallery from '~/components/product-page/FullScreenGallery.vue';
import { NorceMediaSetCodes } from '~/constants/norceCodes';
import { ProductVariantModel } from '~/models/productVariant';

import { storeToRefs } from 'pinia';
import ModalVideoPlayer from '~/components/modals/ModalVideoPlayer.vue';
import useRouterNormalize from '~/composeables/useRouterNormalize';

const { normalizeQuery } = useRouterNormalize();
const route = useRoute();
const props = defineProps<{
  productItem: ProductModel;
  currentImageList: NorceMediaSetCodes;
  activeVariant: ProductVariantModel;
}>();

const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);

const galleryVisible = ref(false);
const startGalleryIndex = ref<number>(0);
const showVideo = ref('');

const onImageClick = (imageIndex: number) => {
  if (images.value[imageIndex].linkedVideo) {
    showVideo.value = images.value[imageIndex].linkedVideo;
    return;
  }

  if (isMobile.value) {
    return;
  }
  startGalleryIndex.value = imageIndex;
  galleryVisible.value = true;
};

const images = computed(()=> {
  if (props.productItem.extendedLayout) {
    return props.productItem.mainProductImage;
  }
  return props.productItem.imagesAndVideo(props.currentImageList, props.activeVariant);
});

const thumbSelected = ref(0);
const mainSwiper = ref<typeof SimpleSwiper | null>(null);

const setSlide = (index: number) => {
  thumbSelected.value = index;
  setSlideUrl(index);
};
const clickThumb = (index: number) => {
  thumbSelected.value = index;
  setSlideUrl(index);
  if (images.value[index].linkedVideo) {
    showVideo.value = images.value[index].linkedVideo;
    return;
  }

  if (mainSwiper.value) {
    mainSwiper.value.goImage(index);
  }
};

const setSlideUrl = (index: number) => {
  history.replaceState(
    {},
    '',
    route.path + '?slide=' + index
  );
};

onMounted(()=> {
  const slideNo = parseInt(normalizeQuery('slide'));
  if (!isNaN(slideNo) && slideNo >= 0 && slideNo < images.value.length) {
    setTimeout(()=> {
      clickThumb(slideNo);
    }, 200);
  }
});

const closeVideo = () => {
  showVideo.value = '';
  history.replaceState(
    {},
    '',
    route.path
  );
};

</script>

<style lang="postcss">
.ProductImage .SimpleSwiper .imageHolder>img {
  @apply desk:max-h-[560px] object-cover w-full !h-full aspect-square !m-auto;
}
.border-animation {
  transition-delay: 0.3s;
}
</style>
