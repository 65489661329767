<template>
  <div class="ProductDescription relative">
    <!-- * * * * * * about -->
    <div
      v-if="productItem.description"
      ref="productDescription"
      class="wysiwyg type-base"
      v-html="productItem.description"
    />
    <div
      class="wysiwyg type-sm"
      :class="{
        'mt-12': productItem.description
      }"
    >
      <!-- * * * * * * brand -->
      <div class="type-headline-sm"> {{ $t('filter.name.brandFilters') }} </div>
      <nuxt-link
        :to="globalContent.getBrandLink(productItem.brand.code) || ''"
        class="btn--text !no-underline"
      >
        {{ productItem.brand.name }}
      </nuxt-link>
    </div>
    <!-- * * * * * * variant -->
    <div class="type-sm mt-16">
      <div class="type-headline-sm"> {{ $t('productPage.partNo') }}</div>
      <span class="block"> {{ activeVariant.partNo }} </span>
    </div>
    <!-- * * * * * * volume -->
    <div v-if="activeVariant.volume" class="type-sm mt-16">
      <div class="type-headline-sm"> {{ activeVariant.volume.name }} </div>
      <span class="block"> {{ activeVariant.volume.value }} </span>
    </div>
    <!-- * * * * * * characteristics -->
    <div v-if="productItem.characteristics?.multipleValues?.length" class="mt-16">
      <div class="type-headline-sm"> {{ productItem.characteristics.name }} </div>
      <div class="flex flex-col gap-8 mt-8">
        <div
          v-for="item in productItem.characteristics?.multipleValues"
          :key="'product-characteristics-' + item.code"
        >
          <div class="flex flex-row items-center gap-8">
            <img
              :alt="item.name"
              :src="item.image ?? '/icons/file-pdf.svg'"
              class="w-20 h-20 select-none pointer-events-none"
            >
            <div class="type-sm leading-single">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductModel } from '~/models/product';
import ProductVariant from '~/models/productVariant';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContent = useGlobalContentStore();

defineProps<{
  productItem: ProductModel,
  activeVariant: ProductVariant,
}>();

</script>

<style lang="postcss">

.ProductDescription p {
  @apply type-sm;
}
.ProductDescription ul {
  @apply type-sm !mt-12;
}

</style>
